<template>
  <div id="actionDialog">
    <el-dialog
      title="批量编辑"
      :visible.sync="show"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @open="open"
      @closed="reset"
      width="500px"
    >
      <el-form
        v-loading="loading"
        :model="actionForm"
        ref="actionForm"
        label-width="80px"
      >
        <el-form-item label="始终入场">
          <el-radio-group v-model="actionForm.alwaysOpen">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="租赁类型">
          <el-radio-group v-model="type">
            <el-radio label="免费"></el-radio>
            <el-radio label="收费" disabled></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="车辆归属">
          <el-input
            v-model="actionForm.subjectName"
            placeholder="请输入车辆归属"
          ></el-input>
        </el-form-item>
        <el-form-item label="套餐">
          <el-select v-model="actionForm.priceId" placeholder="请选择套餐">
            <el-option
              v-for="item of priceList"
              :key="item.priceId"
              :label="item.priceName"
              :value="item.priceId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间范围" v-if="type !== '无'">
          <div
            class="leaseTime"
            v-for="(leaseTime, leaseTimeIndex) of leaseTimes"
            :key="leaseTimeIndex"
            :style="{
              marginBottom:
                leaseTimeIndex === leaseTimes.length - 1 ? '0' : '10px',
            }"
          >
            <el-date-picker
              v-model="leaseTimes[leaseTimeIndex]"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              @change="
                (value) => {
                  leaseTimesChange(value, leaseTimeIndex);
                }
              "
            >
            </el-date-picker>
            <span
              class="remove-leaseTime"
              @click="removeLeaseTime(leaseTimeIndex)"
              >移除</span
            >
          </div>
          <el-button
            type="primary"
            plain
            class="createTime"
            @click="createLeaseTime"
            >新增时间</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" size="medium" @click="show = false"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      actionList: [],
      show: false,
      loading: false,
      type: "免费",
      priceList: [],
      parkingLotId: "",
      leaseTimes: [],
      actionForm: {
        priceId: "",
        subjectName: "",
        alwaysOpen: null,
      },
    };
  },
  methods: {
    leaseTimesChange(value, leaseTimeIndex) {
      this.leaseTimes[leaseTimeIndex] = [
        value[0],
        this.$moment(value[1]).format("YYYY-MM-DDT23:59:59.SSSZZ"),
      ];
      this.$forceUpdate();
    },
    open() {
      this.parkingLotId = this.$route.params.id;
      this.getPriceList();
    },
    removeLeaseTime(index) {
      this.leaseTimes.splice(index, 1);
    },
    createLeaseTime() {
      if (this.leaseTimes.length === 0) {
        this.leaseTimes.push([undefined, undefined]);
      } else {
        if (
          this.leaseTimes[this.leaseTimes.length - 1][0] &&
          this.leaseTimes[this.leaseTimes.length - 1][1]
        ) {
          this.leaseTimes.push([undefined, undefined]);
        } else {
          this.$message.warning("请先选择上一个时间范围");
        }
      }
    },
    async getPriceList() {
      let res = await this.$http.get(`/price/simple/${this.parkingLotId}/list`);
      this.priceList = res.data.filter((item) => {
        return item.priceVersion !== "临时" && item.priceVersion !== "充电";
      });
    },
    reset() {
      this.actionList = [];
      this.leaseTimes = [];
      this.$set(this, "actionForm", {
        priceId: "",
        subjectName: "",
        alwaysOpen: null,
      });
      this.$refs["actionForm"].resetFields();
    },
    async confirmBtn() {
      try {
        if (
          this.leaseTimes.length &&
          (!this.leaseTimes[this.leaseTimes.length - 1][0] ||
            !this.leaseTimes[this.leaseTimes.length - 1][1])
        ) {
          this.$message.warning("时间范围不能为空");
          return;
        }

        this.loading = true;
        const leaseIds = [];
        for (let item of this.actionList) {
          leaseIds.push(item.leaseId);
        }

        let res = await this.$http.post("/price/lease/edit/batch", {
          ...this.actionForm,
          alwaysOpen: this.actionForm.alwaysOpen ?? null,
          leaseTimes: this.leaseTimes.map((item) => {
            return {
              startTime: item[0],
              endTime: item[1],
            };
          }),
          priceId: this.actionForm.priceId ? this.actionForm.priceId : null,
          leaseIds,
        });
        if (res.code === 0) {
          this.$message.success("批量执行完成");
          this.$emit("refreshList");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#actionDialog {
  .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);

        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;

          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }

        .el-dialog__headerbtn {
          position: initial;

          i {
            color: black;
          }
        }
      }

      .el-dialog__body {
        padding: 15px;

        .el-select,
        .el-date-editor,
        .createTime {
          width: 100%;
        }

        .leaseTime {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .remove-leaseTime {
            white-space: nowrap;
            margin-left: 10px;
            cursor: pointer;
            color: $main-color;
          }
        }

        .table-container {
          .el-table
            .el-table__body-wrapper
            .el-table__body
            td.el-table__cell
            .cell {
            color: black;
          }
        }
      }

      .el-dialog__footer {
        text-align: center;

        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
